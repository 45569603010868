@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: "Noto Sans", "Noto Sans KR", sans-serif, system-ui;
}
:focus {
  z-index: 9999;
}
html,
body {
  height: 100%;
}

html {
  font-size: 12px;
}
body {
  &:has(dialog[open]) {
    overflow: hidden;
  }
}

.label-required {
  position: relative;
  &:after {
    content: "*";
    position: absolute;
    top: 0;
    left: 102%;
    font-size: 12px;
    color: red;
  }
}

.image-plus-box {
  position: relative;
  width: 30%;
  aspect-ratio: 1;
  &:after,
  &:before {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: gray;
  }
  &:after {
    rotate: 90deg;
    transform-origin: top left;
  }
}

.custom-check-button {
  display: inline-block;
  width: 60px;
  aspect-ratio: 2 / 1;
  position: relative;
  border: 2px solid gray;
  border-radius: 45px;
  background: gray;
  transition: all 0.2s ease-in-out;
  &:after {
    content: "";
    height: 100%;
    aspect-ratio: 1;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    background: white;
    transition: all 0.2s ease-in-out;
  }
  &:has(input[type="checkbox"]:checked) {
    background: blue;
    border-color: blue;
    &:after {
      left: 100%;
      translate: -100%;
    }
  }
}
